import { title } from 'process';
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { ToggleSwitch } from 'src/_metronic/_partials/controls/forms/ToggleSwitch';
import { bulkService } from 'src/services/bulk';

interface Props {
  titles: {
    headerInative: string,
    bodyInative: string,
  },
  buttons: {
    cancel: string,
    inative: string
  },
  toastMessage: {
    success: string,
    error: string
  }
  isVisibleModal: boolean,
  closeModal: () => void,
  data: {
    idsToTemoved: number[],
    reload: () => void
    resetIds: () => void,
    save: (ids: number[]) => Promise<void>
  }
  isRequest?: boolean
}
export function ActiveModal({ titles, isVisibleModal, closeModal, buttons, data, toastMessage, isRequest = false }: Props) {

  const [isDeleteActived, setIsDeleteActived] = useState(false)

  return (
    <Modal show={isVisibleModal} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>
          {titles.headerInative}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {titles.bodyInative}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={() => {
            closeModal();
          }}>
          {buttons.cancel}
        </Button>
        <Button
          variant='danger'
          disabled={isRequest}
          onClick={async () => {

            // setIsModalRemoveAllItensSelectedOpen(false);
            try {

              await data.save(data.idsToTemoved);


              data.resetIds()
              data.reload()
              closeModal()
              execToast('success', toastMessage.success, 3000);
            } catch (err) {
              execToast('error', toastMessage.error, 3000);
            }
          }}>

          {buttons.inative}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}