import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ContentPage } from 'src/app/components/contentPage';
import { List } from './components/list';
// import { reasonBlockingService } from 'src/services/reasonBlocking.service';
import { handleCulture } from 'src/utils/culture';
import { useLang } from 'src/_metronic/i18n';
import { IoIosEye } from 'react-icons/io';
import { toAbsoluteUrl } from 'src/_metronic/_helpers';
import SVG from 'react-inlinesvg';
import { Button, Modal } from 'react-bootstrap';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { connectivityNotificationService } from 'src/services/connectivityNotification.service';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';

let debounce: NodeJS.Timeout
export function ConnectivityNotification() {
  const intl = useIntl()
  const lang = useLang();

  const { redirectView, actionsIsDesatived } = usePermisssion();
  const [isRead, setIsRead] = useState(false);

  const [tablePaginationOptions, setTablePaginationOptions] = useState({
    custom: true,
    totalSize: 0,
    sizePerPageList: window.sizePerPageList,
    sizePerPage: 10,
    page: 1
  });
  const [status, setStatus] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [list, setList] = useState<any[]>([])

  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalActivced, setOpenModalActivced] = useState(false)
  const [itemSelected, setItemSelected] = useState({
    name: '',
    id: 0
  })

  function headerTools() {
    return (
      <>
        <div style={{ width: '8vw' }}>
          <select className='form-control' onChange={e => setStatus(+e.target.value)} value={status}>
            <option value='2'>{intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_STATUS.ALL' })}</option>
            <option value='1'>{intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_STATUS.ACTIVE' })}</option>
            <option value='0'>{intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_STATUS.DESACTIVE' })}</option>
          </select>
        </div>

        <div style={{ width: '8vw', marginLeft: '10px' }}>
          <input
            onChange={({ target }) => {
              if (debounce) clearTimeout(debounce);
              debounce = setTimeout(() => {
                setSearch(target.value);
              }, 500);
            }}
            className='form-control'
            type='text'
            placeholder={intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_INPUT.SEARCH' })}
          />
        </div>

        <div style={{ display: 'flex', gap: '5px' }}>
          <Link
            title={intl.formatMessage({ id: 'PAGE.Connectivity.Notification.add' })}
            type='button'
            className='btn btn-primary ml-5 mr-2'
            to='/connectivity-notification/create'>
            {intl.formatMessage({ id: 'PAGE.Connectivity.Notification.add' })}
          </Link>
        </div>


      </>
    )
  }

  function handleQuantityItensInTable(value: number) {
    setTablePaginationOptions(values => {
      return {
        ...values,
        sizePerPage: value
      }
    })
  }

  function handlePage(value: number) {
    setTablePaginationOptions(values => {
      return {
        ...values,
        page: value
      }
    })
  }

  function RenderAction(item: any) {
    if (item.isEnabled) {
      return (
        <>

          <Link className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3' to={`/connectivity-notification/edit/${item.id}`}>
            <span className='svg-icon svg-icon-md svg-icon-primary'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
            </span>
          </Link>

          <a
            className='btn btn-icon btn-light btn-hover-danger btn-sm mx-3'
            onClick={() => {
              setItemSelected({
                id: item.id,
                name: item.description
              })

              setOpenModalDelete(true)
            }}>
            <span className='svg-icon svg-icon-md svg-icon-danger'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
            </span>
          </a>
        </>
      );
    }

    return (
      <>
        <a className='btn btn-icon btn-icon btn-light btn-hover-success btn-sm mx-3' onClick={() => {
          setItemSelected({
            id: item.id,
            name: item.description
          })
          setOpenModalActivced(true)
        }}>
          <span className='svg-icon svg-icon-md svg-icon-success'>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Check.svg')} />
          </span>
        </a>
      </>
    );
  }

  async function load() {
    const params = {
      value: search,
      culture: handleCulture(lang),
      pageSize: tablePaginationOptions.sizePerPage,
      pageNumber: tablePaginationOptions.page,
      status: status,
      sortOrder: 'desc',
      sortField: 'description',
    }

    try {
      const { data } = await connectivityNotificationService.list(params)

      console.log({ a: data.data.data })
      const values = data.data.data.map((item: any) => {
        return {
          id: item.id,
          description: item.description,
          actions: RenderAction(item)
        }
      })

      setList(values)
      setTablePaginationOptions(values => {
        return {
          ...values,
          totalSize: data.data.total
        }
      })

    } catch (e) {

    }
  }


  useEffect(() => {
    load()
  }, [search, status, tablePaginationOptions.page, tablePaginationOptions.sizePerPage])



  useEffect(() => {
    redirectView(25);
    const value = actionsIsDesatived(25);
    setIsRead(value);
  }, []);

  return (
    <ContentPage
      title={intl.formatMessage({ id: 'PAGE.Connectivity.Notification.title' })}
      addItemTitle={intl.formatMessage({ id: 'PAGE.Connectivity.Notification.add' })}
      linkPageCreate="/connectivity-notification/create"
      LongFilters={{
        culture: handleCulture(lang),
        pageNumber: tablePaginationOptions.page,
        pageSize: tablePaginationOptions.sizePerPage,
        sortField: 'description',
        sortOrder: 'desc',
        status: status
      }}
      logUrl=''
      logNameFile=''
      logTotalItens={tablePaginationOptions.totalSize}
      logHeaders={[]}
      plusOptionsInToobar={true}
      plusOptionsRender={headerTools()}
      disabledOptionsHeader={false}
    >
      <List
        tablePaginationOptions={tablePaginationOptions}
        handleQuantityItensInTable={handleQuantityItensInTable}
        handlePage={handlePage}
        intl={intl}
        values={list}
      />

      <Modal show={openModalDelete} onHide={() => setOpenModalDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {intl.formatMessage({ id: 'TOAST.DELETE_BUTTON' })} : {itemSelected.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {intl.formatMessage({
            id: 'PAGE.Connectivity.Notification.MODAL.DELETE'
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              setOpenModalDelete(false);
            }}>
            {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
          </Button>
          <Button
            variant='danger'
            disabled={isRead}
            onClick={async () => {
              if (isRead) return

              setOpenModalDelete(false);
              await connectivityNotificationService.delete(itemSelected.id);
              load()
              execToast('success', intl.formatMessage({ id: 'PAGE.BLUEPRINT.TOAST_INATIVE' }), 3000);
            }}>
            {intl.formatMessage({ id: 'TOAST.DELETE_BUTTON' })}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={openModalActivced} onHide={() => setOpenModalActivced(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {intl.formatMessage({ id: 'TOAST.ENABLE' })} : {itemSelected.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {intl.formatMessage({
            id: 'PAGE.Connectivity.Notification.MODAL.ATIVE'
          })}{' '}
          {itemSelected.name} ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              setOpenModalActivced(false);
            }}>
            {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
          </Button>
          <Button
            disabled={isRead}
            variant='primary'
            onClick={async () => {
              if (isRead) return
              await connectivityNotificationService.activate(itemSelected.id)
              setOpenModalActivced(false);
              load();
              execToast('success', intl.formatMessage({ id: 'PAGE.BLUEPRINT.TOAST_ATIVE' }), 3000);
            }}>
            {intl.formatMessage({ id: 'TOAST.ENABLE' })}
          </Button>
        </Modal.Footer>
      </Modal>

    </ContentPage>
  )
}