/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';
import { RiRoadMapFill } from 'react-icons/ri';
// AiFillAlert
import { AiFillAlert } from 'react-icons/ai';
import { BsPinMapFill, BsShieldLockFill } from 'react-icons/bs';
import { GiRadarSweep } from 'react-icons/gi';
// import {Row} from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { USER_PERMISSIONS_PROPS } from 'src/redux/userPermissions/types';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';

import './styles.css';
import { MODE_TYPE } from 'src/redux/isMode/types';
import { DEVICE_NOTIFICATION_ROUTES } from 'src/app/modules/devices-notifications/config/route';
import { TEMPORARY_CARDS } from 'src/app/modules/temporary-cards/config/route';
import { IoRadioOutline } from 'react-icons/io5';

import { CustomLabels } from 'src/utils/customLabels';

type TCurrentAccordion = 'create' | 'access' | 'reports' | 'config' | '';
type TCurrentAccordionReducer = (state: TCurrentAccordion, newState: TCurrentAccordion) => TCurrentAccordion;

export default function AsideMenuList({ layoutProps }: any) {
  const [currentAccordion, toggleCurrentAccordion] = useReducer<TCurrentAccordionReducer>(
    (state, newState) => (state === newState ? '' : newState),
    ''
  );

  const intl = useIntl();
  const { viewMenu, moduleOnly } = usePermisssion();
  const location = useLocation();
  const getMenuItemActive = (url: any, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
    // return '';
  };

  const customLabels = new CustomLabels()

  const permissions = useSelector<any, USER_PERMISSIONS_PROPS>((stage) => stage.userPermissions);

  const { isDemo } = useSelector<any, MODE_TYPE>((stage) => stage.userMode);

  function getPermissionModule(id: number) {
    return permissions.modules.find((permission) => permission.id === id)?.permission;
  }

  const ModuleInserteds = getPermissionModule(3);
  const ModuleAcesss = getPermissionModule(4);
  const ModuleRegisters = getPermissionModule(5);
  const ModuleConfigs = getPermissionModule(6);

  const Configs = ModuleConfigs !== 1 || viewMenu({ idModule: 6, idFunctionality: 0 }).menuFatherIsVisible;
  const Reports = ModuleRegisters !== 1 || viewMenu({ idModule: 5, idFunctionality: 0 }).menuFatherIsVisible;
  const Access = ModuleAcesss !== 1 || viewMenu({ idModule: 4, idFunctionality: 0 }).menuFatherIsVisible;
  const Inserteds = ModuleInserteds !== 1 || viewMenu({ idModule: 3, idFunctionality: 0 }).menuFatherIsVisible;



  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav`}>
        {/* REGISTRATION */}
        <li className={`menu-item menu-item-open mt-5`} aria-haspopup='true'>
          <NavLink className='menu-link menu-toggle' to='/dashboard'>
            <span className='svg-icon menu-icon'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')} />
            </span>
            <span className='menu-text'>
              <FormattedMessage id='DASHBOARD' />
            </span>
            {/* <i className='menu-arrow' /> */}
          </NavLink>
        </li>

        {!moduleOnly(7).isRedirect && (
          <li
            // style={!isDemo ? { display: "none" } : {}}
            className={`menu-item menu-item-open`}
            aria-haspopup='true'>
            <NavLink className='menu-link menu-toggle' to='/monitoring'>
              <span className='menu-icon ICONR'>
                {/* <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')} /> */}
                <GiRadarSweep size={18} />
                {/* <GiRadarSweep size={18} color="red" /> */}
                {/* <RiRoadMapFill /> */}
              </span>
              <span className='menu-text'>
                <FormattedMessage id='ITEM_MENU_MONITOR' />
              </span>
              {/* <i className='menu-arrow' /> */}
            </NavLink>
          </li>
        )}
        {/* correct 9 */}
        {!moduleOnly(7).isRedirect && (
          <li
            // style={!isDemo ? { display: "none" } : {}}
            className={`menu-item menu-item-open`}
            aria-haspopup='true'>
            <NavLink className='menu-link menu-toggle' to='/vehiclemonitoring'>
              <span className='menu-icon ICONR'>
                <IoRadioOutline size={18} />

              </span>
              <span className='menu-text'>
                <FormattedMessage id='ITEM_MENU_MONITOR_CAR' />
              </span>
              {/* <i className='menu-arrow' /> */}
            </NavLink>
          </li>
        )}

        {!moduleOnly(8).isRedirect && (
          <li
            // style={!isDemo ? { display: "none" } : {}}
            className={`menu-item menu-item-open`}
            aria-haspopup='true'>
            <NavLink className='menu-link menu-toggle' to='/monitoringalarms'>
              <span className='menu-icon ICONR'>
                {/* <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')} /> */}
                <AiFillAlert size={18} />
                {/* <GiRadarSweep size={18} color="red" /> */}
                {/* <RiRoadMapFill /> */}
              </span>
              <span className='menu-text'>
                <FormattedMessage id='MONITORING.ALARM.TITLE' />
              </span>
              {/* <i className='menu-arrow' /> */}
            </NavLink>
          </li>
        )}

        {!moduleOnly(2).isRedirect && (
          <li className={`menu-item menu-item-open`} aria-haspopup='true'>
            <NavLink className='menu-link menu-toggle' to='/blueprint'>
              <span className='menu-icon ICONR'>
                {/* <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')} /> */}
                <BsPinMapFill size={18} />
                {/* <RiRoadMapFill /> */}
              </span>
              <span className='menu-text'>
                <FormattedMessage id='ASIDE.PLANTDOWN' />
              </span>
              {/* <i className='menu-arrow' /> */}
            </NavLink>
          </li>
        )}

        {Inserteds && (
          // <li className={`menu-item menu-item-open`} aria-haspopup='true'>
          <li className='menu-item menu-item-open' aria-haspopup='true'>
            <div
              className='menu-link '
              onClick={() => {
                toggleCurrentAccordion('create');
              }}>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Edit.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='ASIDE_MENU.REGISTER' />
              </span>
            </div>

            {currentAccordion === 'create' && (
              <>
                {viewMenu({ idModule: 3, idFunctionality: 1 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      <li
                        className={`menu-item menu-item-submenu  ${getMenuItemActive('/people', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/people'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            {
                              customLabels.getCustomLabel({
                                defaultName: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.PERSON' }),
                                inputName: 'Pessoas',
                                intl
                              })
                            }

                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}

                {/*VISITORS*/}
                {viewMenu({ idModule: 3, idFunctionality: 2 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/visitors', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/visitors'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='ASIDE.MENU.ITEM.VISITORS' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {/*GROUPS*/}
                {viewMenu({ idModule: 3, idFunctionality: 3 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/groups', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/groups'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='ASIDE.MENU.ITEM.GROUP_PERSON' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {/*COMPANIES*/}
                {viewMenu({ idModule: 3, idFunctionality: 4 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/companies', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink id='menu-empresas' className='menu-link' to='/companies'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='COMPANIES.NAME' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {/*Creditos*/}
                {viewMenu({ idModule: 3, idFunctionality: 16 }).menuItemIsVisible && ( //Tem que alterar essa permissão
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/credits', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink id='menu-creditos' className='menu-link' to='/credittype'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='ASIDE_MENU.CREDITS' />
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}

                {viewMenu({ idModule: 3, idFunctionality: 17 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/creditmanagement', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink id='menu-empresas' className='menu-link' to='/creditmanagement'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='ASIDE_MANAGEMENT_TYPES_CREDITS' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {viewMenu({ idModule: 3, idFunctionality: 18 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(TEMPORARY_CARDS.BASE, true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink id='menu-empresas' className='menu-link' to={TEMPORARY_CARDS.BASE}>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='TEMPORARY_CARDS' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {viewMenu({ idModule: 3, idFunctionality: 19 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        // style={!isDemo ? {display: 'none'} : {}}
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/vehicles', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink id='menu-empresas' className='menu-link' to='/vehicles'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='ASIDE_MANAGEMENT_VEHICLES' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {viewMenu({ idModule: 3, idFunctionality: 20 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        // style={!isDemo ? {display: 'none'} : {}}
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/parkingregister', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink id='menu-empresas' className='menu-link' to='/parkingregister'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='ASIDE.MENU.ITEM.PARKING' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {viewMenu({ idModule: 3, idFunctionality: 28 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        // style={!isDemo ? {display: 'none'} : {}}
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/reason-for-blocking', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink id='menu-empresas' className='menu-link' to='/reason-for-blocking'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='PAGE.BLOCKING.REASONS' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {viewMenu({ idModule: 3, idFunctionality: 27 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        // style={!isDemo ? {display: 'none'} : {}}
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/blocked-people', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink id='menu-empresas' className='menu-link' to='/blocked-people'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='PAGE.LIST.BLOCKING.TITLE' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {viewMenu({ idModule: 3, idFunctionality: 26 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        // style={!isDemo ? {display: 'none'} : {}}
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/custom-field', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink id='menu-empresas' className='menu-link' to='/custom-field'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='PAGE.CUSTOM.INPUTS' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}
              </>
            )}
          </li>
        )}

        {/* ACCESS */}
        {/* <li className="menu-section ">
          <h4 className="menu-text">{intl.formatMessage({id: "ASIDE_MENU.ACCESS"})}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}

        {Access && (
          // <li className={`menu-item menu-item-open`} aria-haspopup='true' data-menu-toggle='hover'>
          <li className='menu-item menu-item-open' aria-haspopup='true'>
            <div
              className='menu-link '
              onClick={() => {
                toggleCurrentAccordion('access');
              }}>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/General/Lock.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='ASIDE_MENU.ACCESS' />
              </span>
              {/* <i className='menu-arrow' /> */}
            </div>

            {currentAccordion === 'access' && (
              <>
                {viewMenu({ idModule: 4, idFunctionality: 5 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/* AREAS */}
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/area', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink id='menu-areas' className='menu-link' to='/area'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='ASIDE.MENU.ITEM.AREA' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {/*DEVICES*/}
                {viewMenu({ idModule: 4, idFunctionality: 6 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/devices', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/devices'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='ASIDE.MENU.ITEM.DEVICES' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {viewMenu({ idModule: 4, idFunctionality: 7 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/* AREAS */}
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/times', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/times'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='ASIDE.MENU.ITEM.SCHEDULE' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {/* ACCESS RULES */}
                {viewMenu({ idModule: 4, idFunctionality: 8 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/accessrules', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/accessrules'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='ASIDE.MENU.ITEM.ACRULE' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {/*RULES exception */}
                {viewMenu({ idModule: 4, idFunctionality: 8 }).menuItemIsVisible && (
                  <div className='menu-submenu ' >
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/exceptionlistrules', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/exceptionlistrules'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='ASIDE.MENU.ITEM.RULESEXCEPRION' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}
                {/* VEHICLE ACCESS RULES */}
                {viewMenu({ idModule: 4, idFunctionality: 24 }).menuItemIsVisible && (

                  <div className='menu-submenu'>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/vehicleaccessrules', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/vehicleaccessrules'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='ACCESS.VEHICLE_ACCESS_RULE' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {/* Schedule Inactivation */}
                {viewMenu({ idModule: 4, idFunctionality: 21 }).menuItemIsVisible && (
                  <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/schedule-inactivation', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/schedule-inactivation'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            {/* <FormattedMessage id='ASIDE.MENU.ITEM.ACRULE' /> */}
                            {intl.formatMessage({ id: 'SCHEDULE_INACTIVATION' })}
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {/* Schedule Inactivation */}
                {viewMenu({ idModule: 4, idFunctionality: 22 }).menuItemIsVisible && (
                  <div className='menu-submenu' style={{ display: 'none' }}>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                          DEVICE_NOTIFICATION_ROUTES.BASE,
                          true
                        )}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to={DEVICE_NOTIFICATION_ROUTES.BASE}>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>{intl.formatMessage({ id: 'DEVICE_NOTIFICATIONS' })}</span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                )}

                {/* NOTIFICATIONS */}
                {viewMenu({ idModule: 4, idFunctionality: 15 }).menuItemIsVisible && (
                  <div className='menu-submenu ' >
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                      {/*begin::2 Level*/}
                      <li
                        style={{}}
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/notifications', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        {/* <li style={!isDemo ? {display: 'none'} : {}} className={`menu-item menu-item-submenu ${getMenuItemActive('/notifications', true)}`} aria-haspopup='true' data-menu-toggle='hover'> */}
                        <NavLink className='menu-link' to='/notifications'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='NOTIFICATION' />
                          </span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                  // <></>
                )}

                {viewMenu({ idModule: 4, idFunctionality: 25 }).menuItemIsVisible && (
                  <div className='menu-submenu ' >
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>

                      <li
                        style={{}}
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/connectivity-notification', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        {/* <li style={!isDemo ? {display: 'none'} : {}} className={`menu-item menu-item-submenu ${getMenuItemActive('/notifications', true)}`} aria-haspopup='true' data-menu-toggle='hover'> */}
                        <NavLink className='menu-link' to='/connectivity-notification'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='PAGE.Connectivity.Notification.title' />
                          </span>
                        </NavLink>
                      </li>

                    </ul>
                  </div>

                )}
              </>
            )}
          </li>
        )}

        {/*Access Log*/}
        {Reports && (
          // <li className={`menu-item menu-item-open`} aria-haspopup='true' data-menu-toggle='hover'>
          <li className='menu-item menu-item-open' aria-haspopup='true'>
            <div className='menu-link ' onClick={() => toggleCurrentAccordion('reports')}>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Chart-bar2.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='ASIDE_MENU.REPORTS' />
              </span>
              {/* <i className='menu-arrow' /> */}
            </div>
            <div className='menu-submenu '>
              <i className='menu-arrow' />
              <ul className='menu-subnav'>
                {currentAccordion === 'reports' && (
                  <>
                    {viewMenu({ idModule: 5, idFunctionality: 9 }).menuItemIsVisible && (
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/logs', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/logscenter'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='DASH.ACCESS' />
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {viewMenu({ idModule: 5, idFunctionality: 9 }).menuItemIsVisible && (
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/logs', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/logsvehicleaccess'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='DASH.ACCESS_VEHICLES' />
                          </span>
                        </NavLink>
                      </li>
                    )}

                    {viewMenu({ idModule: 5, idFunctionality: 13 }).menuItemIsVisible && (
                      <></>
                      // <li
                      //   className={`menu-item menu-item-submenu ${getMenuItemActive('/alarmHistory', true)}`}
                      //   aria-haspopup='true'
                      //   data-menu-toggle='hover'>
                      //   <NavLink className='menu-link' to='/alarmHistory'>
                      //     <i className='menu-bullet menu-bullet-dot'>
                      //       <span />
                      //     </i>
                      //     <span className='menu-text'>
                      //       <FormattedMessage id='ALARM_HISTORY.TABLE.INPUTS.LABEL.ALARM' />
                      //     </span>
                      //   </NavLink>
                      // </li>
                    )}

                    {viewMenu({ idModule: 5, idFunctionality: 14 }).menuItemIsVisible && (
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/centerlogpermissions', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/centerlogpermissions'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='REPORTS.PERMISSION' />
                          </span>
                        </NavLink>
                      </li>
                    )}

                    {viewMenu({ idModule: 5, idFunctionality: 10 }).menuItemIsVisible && (
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/registersCenter', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/registersCenter'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='MENU.REGISTER' />
                          </span>
                        </NavLink>
                      </li>
                    )}

                    {viewMenu({ idModule: 5, idFunctionality: 23 }).menuItemIsVisible && (
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/currentStatus', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/currentStatus'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='REPORTS.CURRENT_STATUS' />
                          </span>
                        </NavLink>
                      </li>
                    )}

                    {viewMenu({ idModule: 5, idFunctionality: 13 }).menuItemIsVisible && (
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/auditlogs', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/auditlogs'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='REPORTS.AUDITLOG' />
                          </span>
                        </NavLink>
                      </li>
                    )}

                    {viewMenu({ idModule: 5, idFunctionality: 13 }).menuItemIsVisible && (
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/pdfdownload', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/pdfdownload'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='PDF.LIST.PAGE.TILE' />
                          </span>
                        </NavLink>
                      </li>
                    )}

                  </>
                )}
              </ul>
            </div>
          </li>
        )}

        {/* Configs */}
        {Configs && (
          // <li className={`menu-item menu-item-open`} aria-haspopup='true' data-menu-toggle='hover'>
          <li className='menu-item menu-item-open' aria-haspopup='true'>
            <div
              className='menu-link '
              onClick={() => {
                toggleCurrentAccordion('config');
              }}>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/General/Settings-2.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='ASIDE_MENU.CONFIG' />
              </span>
            </div>
            <div className='menu-submenu '>
              <i className='menu-arrow' />
              <ul className='menu-subnav'>
                {currentAccordion === 'config' && (
                  <>
                    {viewMenu({ idModule: 6, idFunctionality: 11 }).menuItemIsVisible && (
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('/config', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/config'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>
                            <FormattedMessage id='ASIDE_MENU.CONFIG_item' />
                          </span>
                        </NavLink>
                      </li>
                    )}

                    {viewMenu({ idModule: 6, idFunctionality: 12 }).menuItemIsVisible && (
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive('access', true)}`}
                        aria-haspopup='true'
                        data-menu-toggle='hover'>
                        <NavLink className='menu-link' to='/access'>
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>

                          <span className='menu-text'>
                            {intl.formatMessage({
                              id: 'OPERATOR_ROLE_TITLE_LIST'
                            })}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  </>
                )}
              </ul>
            </div>
          </li>
        )}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
