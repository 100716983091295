import { Reducer } from 'redux';

import produce from 'immer'; //immer
import { USER_PERMISSIONS_PROPS } from './types';

/* id dos modulos
1 => dashboar
2 => planta baixa 
3 => cadastros
4 => acesso
5 => relatorios
6 => configurações
7 => monitoramento de acesso
8 => monitoramento de alarme

*/

const INITIAL_STATE: USER_PERMISSIONS_PROPS = {
  modules: [
    {
      id: 2,
      permission: 1
    },
    {
      id: 3,
      permission: 1
    },
    {
      id: 4,
      permission: 1
    },
    {
      id: 5,
      permission: 1
    },
    {
      id: 6,
      permission: 1
    },
    {
      id: 7,
      permission: 1
    },
    {
      id: 8,
      permission: 1
    },
  ],
  functionalities: [
    {
      id_module: 3,
      id: 1,
      permission: 1
    },
    {
      id_module: 3,
      id: 2,
      permission: 1
    },
    {
      id_module: 3,
      id: 3,
      permission: 1
    },
    {
      id_module: 3,
      id: 4,
      permission: 1
    },
    {
      id_module: 3,
      id: 17,
      permission: 1
    },
    {
      id_module: 3,
      id: 18,
      permission: 1
    },
    {
      id_module: 3,
      id: 19,
      permission: 1
    },
    {
      id_module: 3,
      id: 20,
      permission: 1
    },
    {
      id_module: 4,
      id: 5,
      permission: 1
    },
    {
      id_module: 4,
      id: 6,
      permission: 1
    },
    {
      id_module: 4,
      id: 7,
      permission: 1
    },
    {
      id_module: 4,
      id: 8,
      permission: 1
    },
    {
      id_module: 4,
      id: 15,
      permission: 1
    },
    {
      id_module: 4,
      id: 21,
      permission: 1
    },
    {
      id_module: 4,
      id: 22,
      permission: 1
    },
    {
      id_module: 5,
      id: 9,
      permission: 1
    },
    {
      id_module: 5,
      id: 10,
      permission: 1
    },
    {
      id_module: 5,
      id: 13,
      permission: 1
    },
    {
      id_module: 5,
      id: 14,
      permission: 1
    },
    {
      id_module: 5,
      id: 23,
      permission: 1
    },
    {
      id_module: 6,
      id: 11,
      permission: 1
    },
    {
      id_module: 6,
      id: 12,
      permission: 1
    },
    {
      id_module: 3,
      id: 26,
      permission: 1
    },
    {
      id_module: 3,
      id: 27,
      permission: 1
    },
    {
      id_module: 3,
      id: 28,
      permission: 1
    },
  ]
};

const userPermissions: Reducer<USER_PERMISSIONS_PROPS> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_PERMISSIONS': {
        const modules = action.payload.modules;
        const functionalities = action.payload.functionalities;

        draft.modules = modules;
        draft.functionalities = functionalities;

        return draft;
      }
      default: {
        return draft;
      }
    }
  });
};

export default userPermissions;
