import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';

import { ContentPage } from 'src/app/components/contentPage';

// import { reasonBlockingService } from 'src/services/reasonBlocking.service';
import { execToast } from 'src/_metronic/_partials/controls/Toast';

import './create.style.sass'
import { useIntl } from 'react-intl';
import { ToggleSwitch } from 'src/_metronic/_partials/controls/forms/ToggleSwitch';
import { AsyncPaginate } from 'react-select-async-paginate';

//@ts-ignore
import { CSSObjectWithLabel } from 'react-select';
import { getAllDevices } from 'src/services/devicesCrud';
import { connectivityNotificationService } from 'src/services/connectivityNotification.service';
import { componentsModifications } from 'src/utils/componentsModification/ComponentsModification';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';

const selectStyle = {
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    border: '1px solid #E4E6EF',
    '&:hover': {
      cursor: 'pointer'
    }
  })
};
export function ConnectivityNotificationCreate() {
  const intl = useIntl()
  const history = useHistory()
  const params: any = useParams()

  const [description, setDescription] = useState('')
  const [emailTitle, setEmailTitle] = useState('')
  const [emailRecipients, setEmailRecipients] = useState('')
  const [emailBody, setEmailBody] = useState('')
  const [disconnectionThresholdMinutes, setDisconnectionThresholdMinutes] = useState(60)
  const [resendFrequencyHours, setResendFrequencyHours] = useState(12)
  const [sendMailToReconection, setSendMailToReconection] = useState(false)
  const [appliesToAllEquipments, setAppliesToAllEquipments] = useState(true)
  const [devices, setDevices] = useState<{ value: number, label: string }[]>([])

  const { redirectView, actionsIsDesatived } = usePermisssion();
  const [isRead, setIsRead] = useState(false);
  async function handleSave(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (isRead) return
    try {
      const payload = {
        "description": description,
        "sendEmailOnReconnection": sendMailToReconection,
        "disconnectionThresholdMinutes": disconnectionThresholdMinutes,
        "resendFrequencyHours": resendFrequencyHours,
        "appliesToAllEquipments": appliesToAllEquipments,
        "emailTitle": emailTitle,
        "emailRecipients": emailRecipients,
        "emailBody": emailBody,
        "connectivityNotificationDevices": devices.map(d => {
          return {
            deviceId: d.value
          }
        })
      }


      if (!payload.description || !payload.emailTitle || !payload.emailRecipients || !payload.emailBody) {

        componentsModifications.valid('[name="description"]')
        componentsModifications.valid('[name="emailTitle"]')
        componentsModifications.valid('[name="emailRecipients"]')
        componentsModifications.valid('[name="emailBody"]')

        throw new Error('Error')

      }

      if ((params as any).id) {
        const id = (params as any).id

        await connectivityNotificationService.edit({
          id,
          ...payload,
          connectivityNotificationDevices: devices.map(d => {
            return {
              deviceId: d.value,
              connectivityNotificationId: id
            }
          })
        })

        execToast('success', intl.formatMessage({ id: 'PAGE.Connectivity.Notification.save.success' }), 3000);
      } else {
        await connectivityNotificationService.create(payload)

        execToast('success', intl.formatMessage({ id: 'PAGE.Connectivity.Notification.save.success' }), 3000);
      }

      history.push('/connectivity-notification')
    } catch (e) {
      execToast('error', intl.formatMessage({ id: 'PAGE.Connectivity.Notification.save.error' }), 3000);
    }
  }

  async function load(id: number) {
    const data = await connectivityNotificationService.getById(id)
    const values = data.data.data

    setDescription(values.description)
    setAppliesToAllEquipments(values.appliesToAllEquipments)
    setDisconnectionThresholdMinutes(values.disconnectionThresholdMinutes)
    setEmailBody(values.emailBody)
    setEmailRecipients(values.emailRecipients)
    setEmailTitle(values.emailTitle)
    setResendFrequencyHours(values.resendFrequencyHours)
    setSendMailToReconection(values.sendEmailOnReconnection)

    const devices = data.data.data.connectivityNotificationDevices.map((d: any) => {
      return {
        label: d.device.name,
        value: d.device.id
      }
    })

    setDevices(devices)

  }

  const getDevices = async (...args: any[]) => {
    const [value, prevOptions, params] = args;
    const page = params?.page || 1;

    const {
      data: {
        data: { data: devices, pages }
      }
    } = await getAllDevices({
      deviceTypes: '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16',
      pageSize: 100,
      pageNumber: page,
      status: 2,
      value: !!value ? value : undefined,
      sortOrder: 'asc',
      sortField: 'Name'
    });

    return {
      options: devices.map((el: any) => ({ value: el.id, label: el.name })),
      hasMore: page < pages,
      additional: {
        page: page + 1
      }
    };
  };

  useEffect(() => {
    if ((params as any).id) {
      load(+(params as any).id)
    }
  }, [])

  useEffect(() => {
    redirectView(25);
    const value = actionsIsDesatived(25);
    setIsRead(value);
  }, []);

  return (
    <ContentPage
      title={intl.formatMessage({ id: params.id ? 'PAGE.Connectivity.Notification.edit' : 'PAGE.Connectivity.Notification.add' })}
      addItemTitle={intl.formatMessage({ id: 'PAGE.Connectivity.Notification.add' })}
      linkPageCreate=""
      LongFilters={{
        culture: '',
        pageNumber: 1,
        pageSize: 1000,
        sortField: '',
        sortOrder: 'description',
        status: 1
      }}
      logUrl='/reason-for-blocking/create'
      logTotalItens={0}
      logNameFile='Motivos bloqueios'
      logHeaders={[]}
      plusOptionsInToobar={false}
      plusOptionsRender={<></>}
      disabledOptionsHeader={true}
    >
      <form id="FormCreate" className='card-body' onSubmit={handleSave}>
        <div className='row mt-5'>
          <div className="col-lg-4 col-xl-4">
            <label htmlFor="">{intl.formatMessage({ id: "SCHEDULE.SCHEDULE.DESCRIPTION" })}</label>
            <input type="text" className='form-control' name="description" value={description} onChange={e => setDescription(e.target.value)} />
          </div>

          <div className="col-lg-4 col-xl-4">
            <label htmlFor="">{intl.formatMessage({ id: "PAGE.Connectivity.Notification.devices.selected" })}</label>
            <AsyncPaginate
              loadingMessage={() => intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_LOADING' })}
              noOptionsMessage={() => intl.formatMessage({ id: 'NOTHING_FOUND' })}
              debounceTimeout={500}
              styles={selectStyle}
              closeMenuOnSelect={false}
              placeholder={intl.formatMessage({ id: 'ACCESS_LOGS.PLACEHOLDERS.SELECT' })}
              loadOptions={getDevices}
              loadOptionsOnMenuOpen
              isMulti
              value={devices}
              onChange={(values: any) => {
                const ids = values ? values : [];
                setDevices(ids);

                if (ids) {
                  setAppliesToAllEquipments(false)
                }

              }}
            />
          </div>

          <div className="col-lg-4 col-xl-4">
            <label htmlFor="" style={{ display: 'block', marginBottom: "10px" }}>{intl.formatMessage({ id: "PAGE.Connectivity.Notification.send.restart.connect" })}</label>

            <ToggleSwitch
              checked={sendMailToReconection}
              onChange={(e) => {
                setSendMailToReconection(e.target.checked);
              }}
            />

          </div>
        </div>

        <div className='row mt-5'>
          <div className="col-lg-4 col-xl-4">
            <label htmlFor="">{intl.formatMessage({ id: "PAGE.Connectivity.Notification.limit.by.send" })}</label>
            <input
              type="number"
              className='form-control'
              name="disconnectionThresholdMinutes"
              value={disconnectionThresholdMinutes}
              onChange={e => setDisconnectionThresholdMinutes(+e.target.value)}
            />
          </div>

          <div className="col-lg-4 col-xl-4">
            <label htmlFor="">{intl.formatMessage({ id: "PAGE.Connectivity.Notification.send.by.hours" })}</label>
            <input
              type="number"
              className='form-control'
              name="resendFrequencyHours"
              value={resendFrequencyHours}
              onChange={e => setResendFrequencyHours(+e.target.value)}
            />
          </div>

          <div className="col-lg-4 col-xl-4 " style={{}}>
            <label htmlFor="" style={{ display: 'block', marginBottom: "10px" }}>{intl.formatMessage({ id: "PAGE.Connectivity.Notification.devices.all" })}</label>
            <ToggleSwitch
              checked={appliesToAllEquipments}
              onChange={(e) => {
                setAppliesToAllEquipments(e.target.checked);

                if (e.target.checked) {
                  setDevices([])
                }
              }}
            />

          </div>


        </div>

        <div className='row mt-5'>
          <div className="col-lg-4 col-xl-4">
            <label htmlFor="">{intl.formatMessage({ id: "PAGE.Connectivity.Notification.email.title" })}</label>
            <input type="text" className='form-control' name="emailTitle" value={emailTitle} onChange={e => setEmailTitle(e.target.value)} />
          </div>

          <div className="col-lg-4 col-xl-4">
            <label htmlFor="">{intl.formatMessage({ id: "PAGE.Connectivity.Notification.send.to" })}</label>
            <input type="text" className='form-control' name="emailRecipients" value={emailRecipients} onChange={e => setEmailRecipients(e.target.value)} />
          </div>

        </div>

        <div className='row mt-5'>
          <div className="col-lg-8 col-xl-8">
            <label htmlFor="">{intl.formatMessage({ id: "PAGE.Connectivity.Notification.email.body" })}</label>
            <textarea className='form-control' name="emailBody" style={{
              height: "85px !important"
            }} value={emailBody} onChange={e => setEmailBody(e.target.value)} />
          </div>
        </div>

        <div className="col-lg-12 col-xl-12 mt-5 action">
          <div className='align-right'>
            <button type="button" className='btn btn-danger mr-5' onClick={() => {
              history.push('/connectivity-notification')
            }}>{intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}</button>
            <button
              disabled={isRead}
              type="submit"
              className='btn btn-primary btn-elevate'>
              {
                (params as any).id ? intl.formatMessage({ id: 'EDIT' }) : intl.formatMessage({ id: 'PERSON.NEWPERSON_SAVE_BUTTON' })
              }
            </button>
          </div>
        </div>
      </form>
    </ContentPage>
  )
} 