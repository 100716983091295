import axios from 'axios';
import { dateToUNIX } from 'src/app/modules/Reports/utils/utils';

interface props {
  areasIds: number[];
  pageNumber: number;
}

export function getRealTimeAccess(data: props) {
  const areas =
    data.areasIds.length === 0
      ? ''
      : data.areasIds.reduce((acc, item, index) => {
        if (index === 0) {
          acc += `?areasIds=${item}`;
        } else {
          acc += `&areasIds=${item}`;
        }

        return acc;
      }, '');

  return axios.get<any>(`${process.env.REACT_APP_IDSECURE_API_URL_REPORTS}/dashboard/logs${areas}`, {
    params: {
      pageSize: 10,
      pageNumber: data.pageNumber,
      sortOrder: 'desc',
      sortField: 'Time'
    }
  });
}

interface Props {
  id: number;
  oldest: boolean;
  idAreas: string;
  types: string;
  devices: string;
}
export function getRealTimeAccessNopag({ id, oldest, idAreas, types, devices }: Props) {
  const params: {
    lastLogId?: number;
    oldest: boolean;
    AreaIds: string;
    EventTypes: string;
    DeviceIds: string;
    Quantity: string
  } = {
    lastLogId: id,
    oldest: oldest,
    AreaIds: idAreas,
    EventTypes: types,
    DeviceIds: devices,
    Quantity: "12",

  };

  if (!id) {
    delete params.lastLogId;
  }
  return axios.get<any>(`${process.env.REACT_APP_IDSECURE_API_URL_REPORTS}/accesslog/last`, {
    params: {
      ...params,
      getPhotos: true,
    }
  });
}
export function getAccessPendingEvent({ ids }: any) {
  const params: {
    logIds: any;
  } = {
    logIds: ids,
  };
  return axios.get<any>(`${process.env.REACT_APP_IDSECURE_API_URL_REPORTS}/accesslog/last`, {
    params: {
      ...params,
      getPhotos: true,
    }
  });
}

export function OpenDoor(id: number) {
  return axios.post(`${process.env.REACT_APP_IDSECURE_API_URL}/devices/${id}/set/6`);
}

interface Props2 {
  id: number;
  oldest: boolean;
  idAreas: string;
  // types: string;
  devices: string;
  AlarmLogCauses: string
}
export function getRealTimeAlarmsMonitoring({ id, oldest, idAreas, devices, AlarmLogCauses }: Props2) {
  const params: {
    lastLogId?: number;
    oldest: boolean;
    AreaIds: string;
    // EventTypes: string;
    DeviceIds: string;
    Quantity: string,
    AlarmLogCauses: string
  } = {
    lastLogId: id,
    oldest: oldest,
    AreaIds: idAreas,
    // EventTypes: types,
    DeviceIds: devices,
    Quantity: "20",
    AlarmLogCauses: AlarmLogCauses
  };

  if (!id) {
    delete params.lastLogId;
  }
  return axios.get<any>(`${process.env.REACT_APP_IDSECURE_API_URL_REPORTS}/reports/monitor/alarmLogs/last`, {
    params
  });
}


export function monitorRandomInspectDone(idDevice: number, logId: number) {
  return axios.post(`${process.env.REACT_APP_IDSECURE_API_URL}/devices/${idDevice}/randomInspectDone/${logId}`)
}