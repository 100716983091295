import React from 'react';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton, ListItemIcon, Menu, MenuItem, Typography, Button } from '@material-ui/core';
import { DownloadCSV } from './DownloadCSV';
import { DownloadExportList } from './DownloadExportList';
import { useIntl } from 'react-intl';

import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from 'src/_metronic/_helpers';
import { PersonAndVisitorsUpload } from './PersonAndVisitorsUpload';
import { UploadCSVBrockPerson } from './UploadCSVBrockPerson';
import { UpLoadCSV } from './UpLoadCSV';


// const options = ['None', 'Atria', 'Callisto', 'Dione', 'Ganymede', 'Hangouts Call', 'Luna', 'Oberon', 'Phobos', 'Pyxis', 'Sedna', 'Titania', 'Triton', 'Umbriel'];

const ITEM_HEIGHT = 48;

export default function LongMenu({
  filtros,
  children,
  upload,
  totalCount,
  linkApi,
  headers,
  columns,
  nomeArquivo,
  invert = false,
  exportList,
  linkApiViewHTML,
  headersHTML,
  csvImport = false,
  csvImportDefault = false,
  ...props
}: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowPersonAndVisitorsUpload, setModalShowPersonAndVisitorsUpload] = React.useState(false);
  const [modalShowUploadDefault, setModalShowUploadDefault] = React.useState(false);

  const open = Boolean(anchorEl);
  const intl = useIntl();

  const qwerStorage: any = localStorage.getItem('QWER');
  const hasLocalStorage = qwerStorage ? JSON.parse(qwerStorage) : null;
  const isAdmin = hasLocalStorage?.qwer;

  function handleClick(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function renderExport() {

    if (window.location.href.includes('blocked-people')) {
      return (<></>)
    }

    if (exportList) {
      return (
        <DownloadExportList
          key={1}
          api={linkApi}
          linkApiViewHTML={linkApiViewHTML}
          // filtro={{ ...filtros }}
          filtro={{ ...filtros, pageSize: totalCount }}
          headers={headersHTML || headers}
          columns={columns}
          nomeArquivo={nomeArquivo}
          onClick={handleClose}></DownloadExportList>
      )
    } else {
      return (
        <DownloadCSV
          key={1}
          api={linkApi}
          filtro={{ ...filtros, pageSize: totalCount }}
          headers={headers}
          columns={columns}
          nomeArquivo={nomeArquivo}
          onClick={handleClose}></DownloadCSV>
      )
    }

  }

  return (
    <div>
      <IconButton size='small' aria-label='More' aria-controls='long-menu' aria-haspopup='true' onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id='long-menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {/* {options.map((option) => (
          <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
            {option}
          </MenuItem>
        ))} */}

        {invert && children && <>{children}</>}


        {
          renderExport()
        }


        {upload ? (
          <>
            {isAdmin ? (
              <>
                <MenuItem
                  onClick={() => {
                    setModalShow(true);
                    handleClose();
                  }}>
                  <ListItemIcon>
                    <span className='svg-icon svg-icon-xl svg-icon-primary'>
                      <SVG src={toAbsoluteUrl('/media/svg/icons/Files/Uploaded-file.svg')} />
                    </span>
                  </ListItemIcon>

                  <Typography variant='inherit' noWrap>
                    {intl.formatMessage({ id: 'FILTER.BUTTON.UPLOAD' })}
                  </Typography>
                </MenuItem>
                <UpLoadCSV show={modalShow} onHide={() => setModalShow(false)} />
              </>
            ) : null}
          </>
        ) : null}

        {
          csvImport && (
            <MenuItem
              onClick={() => {
                setModalShowPersonAndVisitorsUpload(true)
              }}>
              <ListItemIcon>
                <span className='svg-icon svg-icon-xl svg-icon-primary'>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Files/Uploaded-file.svg')} />
                </span>
              </ListItemIcon>

              <Typography variant='inherit' noWrap>
                {intl.formatMessage({ id: 'IMPORT.CSV.TITLE' })}
              </Typography>


            </MenuItem>
          )
        }

        {
          csvImportDefault && (
            <MenuItem
              onClick={() => {
                setModalShowUploadDefault(true)
              }}>
              <ListItemIcon>
                <span className='svg-icon svg-icon-xl svg-icon-primary'>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Files/Uploaded-file.svg')} />
                </span>
              </ListItemIcon>

              <Typography variant='inherit' noWrap>
                {intl.formatMessage({ id: 'IMPORT.CSV.TITLE' })}
              </Typography>


            </MenuItem>
          )
        }

        {children && !invert && <>{children}</>}


      </Menu>

      {
        modalShowPersonAndVisitorsUpload && (
          <PersonAndVisitorsUpload
            show={modalShowPersonAndVisitorsUpload}
            onHide={() => {
              setModalShowPersonAndVisitorsUpload(false)
            }} />
        )

      }

      {
        modalShowUploadDefault && (
          <UploadCSVBrockPerson
            show={modalShowUploadDefault}
            onHide={() => {
              setModalShowUploadDefault(false)
            }} />
        )

      }
    </div>
  );
}
