class ComponentsModifications {
  valid(nameElement: string) {
    try {
      const el: HTMLInputElement | null = document.querySelector(nameElement)
      if (el) {
        el.classList.add('warning')
      }
    } catch (err) {
      console.log(err)
    }
  }
}


export const componentsModifications = new ComponentsModifications()