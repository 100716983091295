import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { UPLOAD_CSV, uploadFilePostApi } from 'src/services/downloadCSV';
import exemplo_person from './exemplo_pessoas.csv';
import exemplo_visitant from './exemplo_visitantes.csv';

import { execToast } from './Toast';

import { setTimeout } from 'timers';
import { Loading } from 'src/_metronic/layout/components/Loading';
import { ToggleSwitch } from './forms/ToggleSwitch';

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};
export function UploadCSVBrockPerson(props: any) {
  const history = useLocation()
  const currentModule = history.pathname

  const [isLoading, setIsLoading] = useState(false)

  const [remoteLink, setRemoteLink] = useState(false)
  const [qrcode, setQrCode] = useState(false)

  const intl = useIntl();
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [switchSectionContent, setSwitchSectionContent] = useState(false);


  const [viewBodyModal, setViewBoryModal] = useState<'file' | 'table'>('file')
  const [tableBodyValues, setTableBodyValues] = useState<{
    id: number,
    nameInDb: string,
    nameInList: string
  }[]>([])

  const [tableValuesInError, setTableValuesInError] = useState<any[]>([])

  const saveFile = (e: any) => {
    setFile(e);
    setFileName(e.name);
  };

  const uploadFile = async (confirm = false) => {
    setIsLoading(true)
    setViewBoryModal('table')

    setSwitchSectionContent(false);
    // props.onHide()
    execToast('success', intl.formatMessage({ id: 'PERSONS.FINGERPRINTS.MODAL.PROCESSING' }), 3000);

    try {

      const path = '/blockedPerson/import'
      await UPLOAD_CSV(confirm, file, fileName, confirm, path, remoteLink, qrcode);


      window.controlID.modules.blockedPeople.service.load()

      setTimeout(async () => {

        execToast('success', intl.formatMessage({ id: 'IMPORT.CONFIRM.TITLE' }), 3000);

        props.onHide();


        setIsLoading(false)
      }, 1000)

    } catch (ex) {
      setIsLoading(false)
      setViewBoryModal('file')
      // props.onHide();
      execToast('error', intl.formatMessage({ id: 'TOAST.UPLOAD.ERROR' }), 3000);
      console.error(ex);
    }
  };

  function errorInImport(retorno: any) {
    // console.log(retorno)

    if (!retorno.response) {
      return
    }

    if (retorno.response.status === 422) {
      const message = retorno.response.data.message

      if (typeof message === "string" && message.includes('461')) {
        execToast('error', intl.formatMessage({ id: 'TOAST.PERSON.ERROR.454' }) + message.split('|').filter((_: string, index: number) => index > 0), false);
      }

      if (typeof message === "string" && message.includes('462')) {
        execToast('error', message.split('|').filter((_: string, index: number) => index > 0) + ' ' + intl.formatMessage({ id: 'TOAST.PERSON.ERROR.462' }), false);
      }

      setViewBoryModal('file')
    }

    const msg = retorno.response.data.message
    if (msg === "453") {
      execToast('error', intl.formatMessage({ id: 'TOAST.PERSON.DOCUMENT' }), false);
      setViewBoryModal('file')
      return
    }
  }



  const onDrop = useCallback((acceptedFiles: any[]) => {
    saveFile(acceptedFiles[0]);
    setFileName(acceptedFiles[0].name);
    setSwitchSectionContent(true);
  }, []);




  return (
    <Modal
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      size='lg'
      onHide={() => {
        props.onHide();
      }}>
      <Modal.Header closeButton={true}>
        <Modal.Title id='contained-modal-title-vcenter'>
          {intl.formatMessage({ id: 'PAGE.LIST.BLOCKING.TITLE.IMPORT' })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='show-grid'>


        <Container>

          <Row>
            {switchSectionContent ? (
              <section className='container' style={{ padding: '0 11px' }}>
                <h5>{intl.formatMessage({ id: 'IMPORT.PERSON.MODAL.SUCESS-MENSAGE' })}</h5>
                <p>{fileName}</p>
              </section>
            ) : (
              <>

                <section className='container' style={{ padding: '0 11px' }}>
                  <h5>{intl.formatMessage({ id: 'IMPORT.PERSON.SUBMIT.TITLE' })}</h5>
                  <Dropzone
                    onDrop={onDrop}
                    maxFiles={1}
                    multiple={false}
                    maxSize={268435456} //250BM
                    accept={{
                      'application/zip': ['.zip'],
                      'application/octet-stream': ['.zip', '.csv'],
                      'text/csv': ['.csv']
                    }}>
                    {({ getRootProps, getInputProps, isFocused, isDragAccept, isDragReject }) => (
                      <div
                        {...getRootProps({
                          className: 'dropzone',
                          style: {
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '30px',
                            borderWidth: 2,
                            borderRadius: 2,
                            borderColor: '#eeeeee',
                            borderStyle: 'dashed',
                            backgroundColor: '#fafafa',
                            color: '#bdbdbd',
                            outline: 'none',
                            transition: 'border .24s ease-in-out',
                            ...(isFocused ? focusedStyle : {}),
                            ...(isDragAccept ? acceptStyle : {}),
                            ...(isDragReject ? rejectStyle : {})
                          }
                        })}>
                        <input {...getInputProps()} />
                        <p className='m-0'>{intl.formatMessage({ id: 'IMPORT.PERSON.DRAGDROP' })}</p>
                      </div>
                    )}
                  </Dropzone>
                </section>
              </>

            )}
          </Row>
        </Container>






      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' disabled={!file} onClick={() => {
          uploadFile()
        }}>
          {intl.formatMessage({ id: 'PERSON.NEWPERSON_UPLOAD' })}
        </Button>

        <Button
          type='button'
          variant='secondary'
          onClick={() => {
            props.onHide();
          }}>
          {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
