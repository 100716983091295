import axios from 'axios';
import qs from 'qs';
import { execToast } from 'src/_metronic/_partials/controls/Toast';

const idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL_REPORTS;
const idsecureCSV = process.env.REACT_APP_IDSECURE_API_URL;
/**
 *
 * @param api - api endpoint
 * @param filtros filtro sendo usado
 * @param headers arquivo de headers do csv
 * @returns axio get
 */
export const getDownloadCSV = (api: string, filtros: any, headers: string[], columns?: string[]) => {

  // let responseAxiosType: 'json' | 'blob' = 'json'

  // if (filtros.hasOwnProperty('returnResponseAxiosIsTypeBlob')) {
  //   responseAxiosType = 'blob'
  // }

  return axios.get(`${idsecureAPI}${api}`, {
    responseType: +filtros.pageSize >= 10000 ? 'json' : 'blob',
    // responseType: responseAxiosType,
    params: { ...filtros, pageNumber: 1, headers: headers, columns: columns },
    paramsSerializer: (prms) => {
      return qs.stringify(prms, { arrayFormat: 'repeat' });
    }
  });
};

interface IgetDownloadList {
  api?: string;
  filtro: any;
  headers: string[];
  columns?: string[];
  title?: string;
  linkApiViewHTML?: string;
}
export const getDownloadList = ({ linkApiViewHTML, filtro, headers, columns, title }: IgetDownloadList) => {

  return axios.get(`${idsecureAPI}${linkApiViewHTML}`, {
    responseType: filtro.pageSize >= 10000 ? 'json' : 'blob',
    params: { ...filtro, pageNumber: 1, headers: headers, columns: columns, title: title },
    paramsSerializer: (prms) => {
      return qs.stringify(prms, { arrayFormat: 'repeat' });
    }
  });
};

export const uploadFilePostApi = async (e: any, file: any, fileName: any, isUpdating = false) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', fileName);
  try {
    const res = await axios.post(`${idsecureCSV}/config/import`, formData, {
      params: {
        isUpdating
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return res;
  } catch (ex) {
    console.error(ex);
    return ex;
  }
};


export const UPLOAD_CSV = async (e: any, file: any, fileName: any, confirm = false, url: string, remoteLink: boolean, qrcode: boolean) => {
  const formData = new FormData();
  formData.append('file', file);
  // formData.append('fileName', fileName);
  try {
    const res = await axios.post(`${idsecureCSV}${url}`, formData, {
      params: {
        confirm: confirm,
        remoteLink: remoteLink,
        qrcode: qrcode
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return res;
  } catch (ex) {
    console.error(ex);
    return ex;
  }
};
