import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next';


import { Pagination } from 'src/_metronic/_partials/controls';

import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { IntlShape } from 'react-intl';
import { NoRecordsFoundMessage, PleaseWaitMessage } from 'src/_metronic/_helpers';

interface ListProps {
  tablePaginationOptions: {
    custom: boolean,
    totalSize: number,
    sizePerPageList: { text: string, value: number }[]
    sizePerPage: number,
    page: number
  },
  intl: IntlShape,
  values: any[],
  handleQuantityItensInTable(v: number): void
  handlePage(v: number): void
}
export function List({ tablePaginationOptions, intl, values, handleQuantityItensInTable, handlePage }: ListProps) {

  const columns = [
    {
      dataField: 'person',
      headerAlign: 'left',
      align: 'left',
      text: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.PERSON' }),
      sort: true
    },

    {
      dataField: 'dtStart',
      headerAlign: 'left',
      align: 'left',
      text: intl.formatMessage({ id: 'PAGE.LIST.REASONS.LIST.DATE.START' }),
      sort: true
    },

    {
      dataField: 'dtEnd',
      headerAlign: 'left',
      align: 'left',
      text: intl.formatMessage({ id: 'PAGE.LIST.REASONS.LIST.DATE.END' }),
      sort: true
    },

    {
      dataField: 'blockReasonDescription',
      headerAlign: 'left',
      align: 'left',
      text: intl.formatMessage({ id: 'PAGE.BLOCKING.REASONS' }),
      sort: true
    },

    {
      dataField: 'actions',
      headerAlign: 'right',
      align: 'right',
      text: intl.formatMessage({ id: 'MENU.ACTIONS' }),
      style: {
        minWidth: '100px'
      },

    }
  ];

  return (
    <PaginationProvider pagination={paginationFactory(tablePaginationOptions)}>
      {({ paginationProps, paginationTableProps }: any) => {
        return (
          <Pagination isLoading={false} paginationProps={paginationProps}>
            <BootstrapTable
              onTableChange={(type, props) => {

                if (type === 'pagination' && tablePaginationOptions.sizePerPage !== props.sizePerPage) {
                  handleQuantityItensInTable(props.sizePerPage)
                  return
                }

                if (type === 'pagination') {
                  handlePage(props.page)
                  return
                }


              }}
              // defaultSorted={[defaultSortedColumn]}
              wrapperClasses='table-responsive'
              bordered={false}
              classes='table table-head-custom table-vertical-center overflow-hidden'
              remote
              bootstrap4
              keyField='id'
              data={values}
              columns={columns}
              // columns={tableColumns(lang, intl)}
              {...paginationTableProps}>
              <PleaseWaitMessage entities={values} />
              <NoRecordsFoundMessage entities={[values]} />
            </BootstrapTable>
          </Pagination>
        );
      }}
    </PaginationProvider>
  )
}