import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Container } from './styles'

export function List() {

  const intl = useIntl();
  const locale = intl.locale;

  type ReleaseNote = {
    title: string;
    description: string;
    date: string;
    version: string;
    hotfix: boolean;
    features: Array<{ title: string }>;
    showDate: boolean;
  };

  let notes: ReleaseNote[] = [];



  if (locale === 'en') {
    notes = require('./releases_en.json').notes;
  } else if (locale === 'es') {
    notes = require('./releases_es.json').notes;
  } else {
    notes = require('./releases_pt.json').notes;
  }

  function versionFormated(version: string, hotfix: boolean) {
    const [day, month, year] = version.split('/')

    const versionLabel = intl.formatMessage({ id: 'VERSION_LABEL' });

    const formattedDate = locale === 'en' ? `${year}.${day}.${month}` : `${year}.${month}.${day}`;

    if (hotfix) {
      return `Hotfix ${formattedDate}`
    }

    return `${versionLabel} ${formattedDate}`;
  }

  const notesFormatted = notes.map(({ version, hotfix, features, showDate }) => {
    return {
      ...notes,
      version,
      hotfix,
      features,
      showDate,
      versionBuild: versionFormated(version, hotfix)
    }
  }).reverse()


  return (
    <Container>
      {
        notesFormatted.map(note => {
          return (
            <article key={note.versionBuild}>
              <span style={{ opacity: note.showDate ? "1" : '0' }}>{note.version}</span>
              <div>
                <section>
                  <p><strong>{note.versionBuild}</strong></p>
                  <ul>
                    {
                      note.features.map(feature => {
                        return (
                          <li key={feature.title}>{feature.title}</li>
                        )
                      })
                    }
                  </ul>
                </section>
              </div>
            </article>
          )
        })
      }
    </Container>
  )
}