import React, { useEffect, useState } from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { useLang } from 'src/_metronic/i18n';
import { defaultDateTime } from 'src/utils/defaultDateTime';
import MaskedTextInput from 'react-text-mask';
import pt from 'date-fns/locale/pt-BR';

import spani from 'date-fns/locale/es';
import ptBr from 'date-fns/locale/pt-BR';
import enUs from 'date-fns/locale/en-US';

interface Props {
  label: string,
  value: Date | null,
  updateValue(value: any): void,
  onlyDate?: boolean,
  id?: string,
  minDate?: Date | null | undefined,
  isClearable?: boolean
  disabled?: boolean
}

export function DateTimeInput({ label, value, updateValue, onlyDate = false, id = '', minDate = null, isClearable = false, disabled = false }: Props) {

  const defaultDateTimeObject = defaultDateTime.getDefault()
  const lang = useLang();

  if (lang === 'es') {
    spani.formatLong = {
      date: () => `${defaultDateTimeObject.date}`,
      dateTime: () => `${defaultDateTimeObject.date} ${defaultDateTimeObject.time}`,
      time: () => `${defaultDateTimeObject.time}`
    }
    registerLocale('_input_', spani);
  }


  if (lang === 'pt') {
    ptBr.formatLong = {
      date: () => `${defaultDateTimeObject.date}`,
      dateTime: () => `${defaultDateTimeObject.date} ${defaultDateTimeObject.time}`,
      time: () => `${defaultDateTimeObject.time}`
    }
    registerLocale('_input_', ptBr);
  }

  if (lang === 'en') {
    enUs.formatLong = {
      date: () => `${defaultDateTimeObject.date}`,
      dateTime: () => `${defaultDateTimeObject.date} ${defaultDateTimeObject.time}`,
      time: () => `${defaultDateTimeObject.time}`
    }
    registerLocale('_input_', enUs);
  }


  const maskModels = defaultDateTime.prepare_mask_component_date()


  let mask = [
    ...maskModels.date,
    ' ',
    ...maskModels.time
  ]

  if (defaultDateTimeObject.time.includes('a')) {
    mask.push(' ')
    mask.push(/[A|P]/gi,)
    mask.push('M')
  }



  if (onlyDate) {
    return (
      <div style={{ width: '100%' }} className='TRANSFORM' id={id}>
        <div className='rdt col-5 p-0'>
          <p className='col-9 mb-0 pl-0'>
            {label}
          </p>
        </div>

        <DatePicker
          locale='_input_'
          showTimeSelect={false}
          dateFormat={`${defaultDateTimeObject.date}`}
          className='form-control'
          disabledKeyboardNavigation
          selected={value}
          onChange={(e) => {
            updateValue(e)
          }}
          minDate={minDate}
          isClearable
          disabled={disabled}
        // customInput={
        //   <MaskedTextInput
        //     type='text'
        //     mask={
        //       maskModels.date
        //     }
        //   />
        // }

        />
      </div>
    )
  }

  return (
    <div style={{ width: '100%' }} className='TRANSFORM' id={id}>
      <div className='rdt col-5 p-0'>
        <p className='col-9 mb-0 pl-0'>
          {label}
        </p>
      </div>

      <DatePicker
        locale='_input_'
        showTimeSelect
        // locale={'pt-BR'}
        // locale='app'
        dateFormat={`${defaultDateTimeObject.date} ${defaultDateTimeObject.time}`}
        className='form-control'
        disabledKeyboardNavigation
        selected={value}
        onChange={(e) => {
          updateValue(e)
        }}
      // customInput={
      //   <MaskedTextInput
      //     type='text'
      //     mask={
      //       mask
      //     }
      //   />
      // }
      // customInput={
      //   <MaskedTextInput
      //     type='text'
      //     mask={[
      //       /[0-9]/,
      //       /[0-9]/,
      //       '/',
      //       /[0-9]/,
      //       /[0-9]/,
      //       '/',
      //       /[0-9]/,
      //       /[0-9]/,
      //       /[0-9]/,
      //       /[0-9]/,
      //       ',',
      //       ' ',
      //       /[0-9]/,
      //       /[0-9]/,
      //       ':',
      //       /[0-9]/,
      //       /[0-9]/
      //     ]}
      //   />
      // }
      // value={a}

      />
    </div>
  )
}