import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls';
import { AsyncPaginate } from 'react-select-async-paginate';
import { MdFilterAlt } from 'react-icons/md';

import { CardMonitor } from '../../../_metronic/layout/components/CardMonitor';

import { MonitorFilter, MonitoringContainer, MonitorResume } from './styles';
import { format } from 'date-fns';
import { Table } from './components/Table';
// @ts-ignore
import { CSSObjectWithLabel } from 'react-select';
import { getAllAreas } from 'src/services/areaCrud';
import {
  loadConfig,
  loadConfigDevices,
  loadConfigTypes,
  saveConfig,
  saveConfigDevices,
  saveConfigTypes
} from './utils/configs';
import { getRealTimeAccessNopag, getRealTimeAlarmsMonitoring, OpenDoor } from 'src/services/monitoring';
import { RiDoorOpenFill } from 'react-icons/ri';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { Loading } from 'src/_metronic/layout/components/Loading';
import { getAllDevices } from 'src/services/permissionLogs';
import { TableContainerB } from './components/Table/styles';
import { DevicesExceptionMode } from 'src/_metronic/_partials/controls/DevicesExceptionMode/DevicesExceptionMode';
import { CardMonitorAlarm } from 'src/_metronic/layout/components/CardMonitorAlarm';
import { defaultDateTime } from 'src/utils/defaultDateTime';
import { modulesConfigCulture } from 'src/utils/modulesControll';

const IMAGES: any = {
  1: 'iDAccess-Perspectiva.jpg',
  2: 'iDAccess_nano.jpg',
  3: 'iDAccess_pro.jpg',
  4: 'iDAccess-Prox-Perspectiva.jpg',
  5: 'iDBlock-Perspectiva.jpg',
  6: 'idblock_balcao.jpg',
  7: 'idblock_bqc.jpg',
  8: 'idblock_facial.jpg',
  15: 'idblock_next.jpg',
  9: 'idblock_pne.jpg',
  10: 'iDBox-Perspectiva.jpg',
  11: 'iDFace.jpg',
  12: 'iDAccess-4x2-Perspectiva.jpg',
  13: 'iDFlex-Frontal.jpg',
  14: 'iDUHF.jpg',
  16: 'iDFaceMax.webp',
  17: 'iduhf-lite.webp'
};

const selectStyle = {
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    border: '1px solid #E4E6EF',
    '&:hover': {
      cursor: 'pointer'
    }
  })
};

interface LOGSPROPS {
  datetime: string;
  area: string;
  device: string;
  id: number;
  typeAlarme: boolean;
  deviceId: number;
}

let realTimeController_2: NodeJS.Timeout | null;
let page = 1;
let isInitalRequest_alert = true;
let idPreSetedt_alert: number[] = [];
let idAreas_alert: number[] = [];
let idTypes_alert: number[] = [];
let idDevices_alert: number[] = [];

let primaryIdLog_alert = 0;
let lastIdLog_alert = 0;
let alterFilter_alert = false;

let noOtherDataCard_alert = false;

export function MonitoringAlarms() {
  const intl = useIntl();

  const [areaSelecteds, setAreaSelected] = useState<any[]>([]);
  const [typesLogsSelecteds, setTypesLogsSelected] = useState<any[]>([]);
  const [devicesSelecteds, setDevicesSelecteds] = useState<any[]>([]);
  const [logs, setLogs] = useState<LOGSPROPS[]>([]);
  const [itemSelected, setItemSelected] = useState(0);

  const LoadRef = useRef<HTMLDivElement>(null);
  const emptyRef = useRef<HTMLDivElement>(null);

  const [isFiltersVisibility, setIsFiltersVisibility] = useState(true);

  const [logIsActived, setLogIsActived] = useState({
    datetime: '',
    name: '',
    area: '',
    auth: '',
    image: '',
    eventClass: false,
    id: 0,
    deviceId: 0,
    deviceName: '',
    deviceImage: ''
  });

  const getAreas = async (value: string) => {
    const {
      data: {
        data: { data: areas, pages }
      }
    } = await getAllAreas({
      pageSize: 100,
      pageNumber: 1,
      // pageNumber: areasPage,
      status: 1,
      ...(value && { value }),
      sortOrder: 'asc',
      sortField: 'Name'
    });

    return {
      options: areas.map((el: any) => {
        return {
          value: el.id,
          label: el.name === 'AREA.DEFAULT' ? intl.formatHTMLMessage({ id: 'AREA.DEFAULT' }) : el.name
        };
      }),
      hasMore: false
    };
  };

  const typesRegistry = [
    {
      value: 1,
      label: intl.formatMessage({ id: 'ALARM_HISTORY.FirstZone' })
    },
    {
      value: 2,
      label: intl.formatMessage({ id: 'ALARM_HISTORY.SecondZone' })
    },
    {
      value: 3,
      label: intl.formatMessage({ id: 'ALARM_HISTORY.ThirdZone' })
    },
    {
      value: 4,
      label: intl.formatMessage({ id: 'ALARM_HISTORY.FourthZone' })
    },
    {
      value: 5,
      label: intl.formatMessage({ id: 'ALARM_HISTORY.FifthZone' })
    },
    {
      value: 6,
      label: intl.formatMessage({ id: 'ALARM_HISTORY.OpenDoor' })
    },

    {
      value: 7,
      label: intl.formatMessage({ id: 'ALARM_HISTORY.Bruteforcing' })
    },
    {
      value: 8,
      label: intl.formatMessage({ id: 'ALARM_HISTORY.PanicFingerprint' })
    },
    {
      value: 9,
      label: intl.formatMessage({ id: 'ALARM_HISTORY.Tamper' })
    },

    {
      value: 10,
      label: intl.formatMessage({ id: 'ALARM_HISTORY.PanicCard' })
    },
    {
      value: 11,
      label: intl.formatMessage({ id: 'ALARM_HISTORY.Online' })
    },
    {
      value: 12,
      label: intl.formatMessage({ id: 'ALARM_HISTORY.Offline' })
    }
  ];

  const getTypesAccess = async (value: string) => {
    const alarms = modulesConfigCulture.getAlarms()

    const typesRegistry = [
      {
        value: 1,
        label: intl.formatMessage({ id: 'ALARM_HISTORY.FirstZone' })
      },
      {
        value: 2,
        label: intl.formatMessage({ id: 'ALARM_HISTORY.SecondZone' })
      },
      {
        value: 3,
        label: intl.formatMessage({ id: 'ALARM_HISTORY.ThirdZone' })
      },
      {
        value: 4,
        label: intl.formatMessage({ id: 'ALARM_HISTORY.FourthZone' })
      },
      {
        value: 5,
        label: intl.formatMessage({ id: 'ALARM_HISTORY.FifthZone' })
      },
      {
        value: 6,
        label: intl.formatMessage({ id: 'ALARM_HISTORY.OpenDoor' })
      },

      {
        value: 7,
        label: intl.formatMessage({ id: 'ALARM_HISTORY.Bruteforcing' })
      },
      {
        value: 8,
        label: intl.formatMessage({ id: 'ALARM_HISTORY.PanicFingerprint' })
      },
      {
        value: 9,
        label: intl.formatMessage({ id: 'ALARM_HISTORY.Tamper' })
      },

      {
        value: 10,
        label: intl.formatMessage({ id: 'ALARM_HISTORY.PanicCard' })
      },
      {
        value: 11,
        label: intl.formatMessage({ id: 'ALARM_HISTORY.Online' })
      },
      {
        value: 12,
        label: intl.formatMessage({ id: 'ALARM_HISTORY.Offline' })
      }
    ].filter(item => alarms.alarmsIds.includes(item.value))

    const filteredTypes = typesRegistry.filter((type: any) => type.label.toLowerCase().includes(value.toLowerCase()));

    return {
      options: filteredTypes,
      hasMore: false
    };
  };

  async function getLogs() {
    const { data } = await getRealTimeAccessNopag({
      id: lastIdLog_alert,
      oldest: true,
      idAreas: idAreas_alert.toString(),
      types: idTypes_alert.toString(),
      devices: idDevices_alert.toString()
    });

    const registerFormat = data.data
      .filter((item: any) => !idPreSetedt_alert.includes(item.id))
      .map((item: any) => {
        const acesssType = eventeTypeName(+item.event);
        return {
          ...item,
          datetime: defaultDateTime.labelDateTime(item.time),
          name: item.personName,
          area: item.areaName === 'AREA.DEFAULT' ? intl.formatHTMLMessage({ id: 'AREA.DEFAULT' }) : item.areaName,
          authorizated: acesssType,
          id: item.id,
          device: item.deviceName,
          eventClass: [7, 11, 12, 15].includes(item.event),
          deviceId: item.deviceId
        };
      });

    try {
      const lastItemIndex = registerFormat.length - 1;
      lastIdLog_alert = registerFormat[lastItemIndex].id;

      // alert(page)
      // setLogs((currentValues) => {
      //   return [...currentValues, ...registerFormat];
      // });
    } catch (e) { }
  }

  function eventeTypeName(value: number) {
    switch (value) {
      case 1:
        return intl.formatMessage({ id: 'EVENTS.INVALID_DEVICE' });
      case 2:
        return intl.formatMessage({ id: 'EVENTS.INVALID_ID_PARAMS' });
      case 3:
        return intl.formatMessage({ id: 'EVENTS.NOT_IDENTIFIED' });
      case 4:
        return intl.formatMessage({ id: 'EVENTS.PENDING_ID' });
      case 5:
        return intl.formatMessage({ id: 'EVENTS.ID_TIMEOUT' });
      case 6:
        return intl.formatMessage({ id: 'EVENTS.ACCESS_DENIED' });
      case 7:
        return intl.formatMessage({ id: 'EVENTS.ACCESS_GRANTED' });
      case 8:
        return intl.formatMessage({ id: 'EVENTS.ACCESS_PENDING' });
      case 9:
        return intl.formatMessage({ id: 'EVENTS.NOT_ADMIN' });
      case 10:
        return intl.formatMessage({ id: 'EVENTS.NOT_ID_ACCESS' });
      case 11:
        return intl.formatMessage({ id: 'EVENTS.BUTTONHOLE_ACCESS' });
      case 12:
        return intl.formatMessage({ id: 'EVENTS.WEB_INTERFACE_ACCESS' });
      case 13:
        return intl.formatMessage({ id: 'EVENTS.GIVEUP_ACCESS' });
      case 14:
        return intl.formatMessage({ id: 'EVENTS.NO_ANSWER' });
      case 15:
        return intl.formatMessage({ id: 'EVENTS.INTERFONIA_ACCESS' });
      default:
        return '';
    }
  }

  function loadingHide() {
    if (LoadRef && LoadRef.current) {
      LoadRef.current.style.display = 'none';
    }
  }

  function loadingShow() {
    if (LoadRef && LoadRef.current) {
      LoadRef.current.style.display = 'block';
    }
  }

  function emptyHide() {
    if (emptyRef && emptyRef.current) {
      emptyRef.current.style.display = 'none';
    }
  }

  function emptyShow() {
    if (emptyRef && emptyRef.current) {
      emptyRef.current.style.display = 'block';
    }
  }

  function RealTime() {
    if (realTimeController_2) {
      clearTimeout(realTimeController_2);
    }

    realTimeController_2 = setTimeout(() => {
      if (window.location.pathname !== '/monitoringalarms') return
      new Promise(async (response, error) => {
        try {
          const { data } = await getRealTimeAlarmsMonitoring({
            id: primaryIdLog_alert,
            oldest: false,
            idAreas: idAreas_alert.toString(),
            // types: idTypes_alert.toString(),
            devices: idDevices_alert.toString(),
            AlarmLogCauses: idTypes_alert.toString()
          });

          //response(data.data);
          response(data.data);
        } catch (e) {
          error(e);
        }
      })
        .then((response: any) => {
          if (alterFilter_alert) {
            idPreSetedt_alert = [];
            primaryIdLog_alert = 0;
          }

          const datasFiltred = response
            .filter((item: any) => !idPreSetedt_alert.includes(item.id))
            .map((item: any) => {
              idPreSetedt_alert.push(item.id);

              // const acesssType = eventeTypeName(+item.event);

              const eventName = typesRegistry.find((event) => event?.value == item?.cause);
              const areaName = item.area.length ? item.area[0].areaTo : '';

              return {
                ...item,
                datetime: defaultDateTime.labelDateTime(item.time),
                device: item.deviceName,
                area: areaName === 'AREA.DEFAULT' ? intl.formatMessage({ id: 'AREA.DEFAULT' }) : areaName,
                typeAlarme: eventName ? eventName.label : '',
                id: item.id
              };
            });

          if (alterFilter_alert) {
            // alert('aqui o problema')
            alterFilter_alert = false;
            idPreSetedt_alert = [];

            setLogs(datasFiltred);
            loadingHide();

            if (datasFiltred.length === 0) {
              emptyShow();
            } else {
              emptyHide();
            }
            // setTimeout(loadingHide,100)
          } else {
            setLogs((currentValues) => {
              if (datasFiltred.length === 0 && currentValues.length === 0) {
                emptyShow();
              } else {
                emptyHide();
              }

              const total = [...datasFiltred, ...currentValues];

              if (total.length > 100) return datasFiltred;

              return [...datasFiltred, ...currentValues];
            });

            if (datasFiltred.length > 0 && !noOtherDataCard_alert) {
              const itemOne = datasFiltred[0];
              // setLogIsActived({
              //   datetime: itemOne.datetime,
              //   area: itemOne.area,
              //   auth: itemOne.authorizated,
              //   name: itemOne.name,
              //   image: !itemOne.personAvatar ? '' : itemOne.personAvatar,
              //   eventClass: [7, 11, 12, 15].includes(itemOne.event),
              //   id: itemOne.id,
              //   deviceId: itemOne.deviceId,
              //   deviceName: itemOne.deviceName,
              //   deviceImage: itemOne.deviceModelType
              // });
            }
          }

          if (datasFiltred.length > 0 && logIsActived.name === '') {
            const logActived = datasFiltred[0];
            primaryIdLog_alert = logActived.id;

            if (!lastIdLog_alert) {
              const lastItemIndex = datasFiltred.length - 1;
              lastIdLog_alert = datasFiltred[lastItemIndex].id;
            }
          }

          if (isInitalRequest_alert) {
            isInitalRequest_alert = false;

            loadingHide();
          }

          setTimeout(RealTime, 300);
        })
        .catch(() => {
          // RealTime();
          //alert('Deu Erro ')
        });
    }, 1500);
  }

  function handleActivedLogById(id: number) {
    const logActived: any = logs.find((log) => log.id === id);

    if (!logActived) return;

    setLogIsActived({
      datetime: logActived.datetime,
      area: logActived.area,
      auth: logActived.authorizated,
      name: logActived.name,
      image: !logActived.personAvatar ? '' : logActived.personAvatar,
      eventClass: [7, 11, 12, 15].includes(logActived.event),
      id: logActived.id,
      deviceId: logActived.deviceId,
      deviceName: logActived.deviceName,
      deviceImage: logActived.deviceModelType
    });

    noOtherDataCard_alert = true;
  }

  async function handleOpenDoor(id: number) {
    try {
      await OpenDoor(id);
      execToast('success', intl.formatMessage({ id: 'DOOR.UNLOCK_SUCCESS' }), 3000);
    } catch (e) {
      execToast('error', intl.formatMessage({ id: 'DOOR.UNLOCK_FAILURE' }), 3000);
    }
  }

  const getDevices = async (value: string) => {
    const {
      data: {
        data: { data: devices, pages }
      }
    } = await getAllDevices({
      // deviceTypes: '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16',
      pageSize: 100,
      pageNumber: 1,
      // pageNumber: devicesPage,
      status: 1,
      ...(value && { value }),
      sortOrder: 'asc',
      sortField: 'Name'
    });

    return {
      options: devices.map((el: any) => ({ value: el.id, label: el.name })),
      hasMore: false
    };
  };

  function resetMonitor() {
    setLogIsActived({
      datetime: '',
      name: '',
      area: '',
      auth: '',
      image: '',
      eventClass: false,
      id: 0,
      deviceId: 0,
      deviceName: '',
      deviceImage: ''
    });
  }

  useEffect(() => {

    primaryIdLog_alert = 0;
    lastIdLog_alert = 0;
    const values = loadConfig();

    setAreaSelected(!values ? [] : values);

    if (values && values.length > 0) {
      idAreas_alert = values.map((item: any) => item.value);
    }

    const valuesTypes = loadConfigTypes();

    setTypesLogsSelected(!valuesTypes ? [] : valuesTypes);

    if (valuesTypes && valuesTypes.length > 0) {
      idTypes_alert = valuesTypes.map((item: any) => item.value);
    }

    const valuesDevices = loadConfigDevices();

    setDevicesSelecteds(!valuesDevices ? [] : valuesDevices);

    if (valuesDevices && valuesDevices.length > 0) {
      idDevices_alert = valuesDevices.map((item: any) => item.value);
    }
  }, []);

  useEffect(() => {
    primaryIdLog_alert = 0;
    lastIdLog_alert = 0;
    RealTime();

    return () => {
      try {
        clearTimeout(realTimeController_2!);
      } catch { }

      page = 1;
      idPreSetedt_alert = [];
      idAreas_alert = [];
      isInitalRequest_alert = true;
      primaryIdLog_alert = 0;
      lastIdLog_alert = 0;
      alterFilter_alert = false;
      noOtherDataCard_alert = false;
    };
  }, []);

  return (
    <Card fluidHeight={null} className={null}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className='monitoring'>
        <CardHeader
          icon={null}
          toolbar={null}
          className={null}
          labelRef={null}
          sticky={false}
          linkAddTitle={undefined}
          title={intl.formatMessage({ id: 'MONITORING.ALARM.TITLE' })}
        />
        <DevicesExceptionMode />
      </div>
      <CardBody fit={null} fluid={null} className={null}>
        <MonitorFilter isVisibility={isFiltersVisibility}>
          <div className='filter'>
            <label className={'form-label'}>{intl.formatMessage({ id: 'ACCESS_LOGS.COLUMNS.DEVICE' })}</label>
            <AsyncPaginate
              loadingMessage={() => intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_LOADING' })}
              noOptionsMessage={() => intl.formatMessage({ id: 'NOTHING_FOUND' })}
              debounceTimeout={500}
              styles={selectStyle}
              closeMenuOnSelect={false}
              placeholder={intl.formatMessage({ id: 'ACCESS_LOGS.PLACEHOLDERS.SELECT' })}
              loadOptions={getDevices}
              loadOptionsOnMenuOpen
              isMulti
              value={devicesSelecteds}
              onChange={(values: any) => {
                primaryIdLog_alert = 0;
                setLogs([]);
                resetMonitor();
                loadingShow();
                const ids = values ? [...values.map(({ value }: any) => value)] : [];

                //saveConfig(values);
                setDevicesSelecteds(values);

                alterFilter_alert = true;
                idDevices_alert = ids;
                saveConfigDevices(values);
              }}
            />
          </div>

          <div className='filter'>
            <label className={'form-label'}>{intl.formatMessage({ id: 'ALARM_HISTORY.TABLE.INPUTS.LABEL.ALARM' })}</label>
            <AsyncPaginate
              loadingMessage={() => intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_LOADING' })}
              noOptionsMessage={() => intl.formatMessage({ id: 'NOTHING_FOUND' })}
              debounceTimeout={500}
              styles={selectStyle}
              closeMenuOnSelect={false}
              placeholder={intl.formatMessage({ id: 'ACCESS_LOGS.PLACEHOLDERS.SELECT' })}
              loadOptions={getTypesAccess}
              loadOptionsOnMenuOpen
              isMulti
              value={typesLogsSelecteds}
              onChange={(values: any) => {
                primaryIdLog_alert = 0;
                setLogs([]);
                resetMonitor();
                loadingShow();

                const ids = values ? [...values.map(({ value }: any) => value)] : [];

                //saveConfig(values);
                setTypesLogsSelected(values);

                alterFilter_alert = true;
                idTypes_alert = ids;
                saveConfigTypes(values);
              }}
            />
          </div>

          <div className='filter'>
            <label className={'form-label'}>{intl.formatMessage({ id: 'ACCESS_LOGS.INPUTS.AREAS' })}</label>
            <AsyncPaginate
              loadingMessage={() => intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_LOADING' })}
              noOptionsMessage={() => intl.formatMessage({ id: 'NOTHING_FOUND' })}
              debounceTimeout={500}
              styles={selectStyle}
              closeMenuOnSelect={false}
              placeholder={intl.formatMessage({ id: 'ACCESS_LOGS.PLACEHOLDERS.SELECT' })}
              loadOptions={getAreas}
              loadOptionsOnMenuOpen
              isMulti
              value={areaSelecteds}
              onChange={(values: any) => {
                primaryIdLog_alert = 0;
                setLogs([]);
                resetMonitor();
                loadingShow();

                const ids = values ? [...values.map(({ value }: any) => value)] : [];

                saveConfig(values);
                setAreaSelected(values);
                idAreas_alert = ids;

                alterFilter_alert = true;
              }}
            />
          </div>

          <div className='hide'>
            <button className='btn btn-default' onClick={() => setIsFiltersVisibility(false)}>
              <MdFilterAlt size='18' />
            </button>
          </div>
        </MonitorFilter>

        {!isFiltersVisibility && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'end'
            }}>
            <button
              style={{
                marginRight: '2.2%'
              }}
              className='btn btn-primary'
              onClick={() => setIsFiltersVisibility(true)}>
              <MdFilterAlt size='18' />
            </button>
          </div>
        )}
        <hr className='MuiDivider-root mb-5 MuiDivider-middle' />

        <MonitoringContainer>
          <h6>{intl.formatMessage({ id: 'MONITORING.ALARM.SUBTITLE' })}</h6>

          <div
            style={{
              display: 'flex'
            }}>
            <div className='containerTableAndBox' style={!logIsActived.id ? { width: '100%' } : {}}>
              <Table
                data={logs}
                nextPage={() => {
                  // getLogs();
                }}
                selected={handleActivedLogById}
                idActived={logIsActived.id}
              />

              <div ref={emptyRef} style={{ display: 'none' }}>
                <TableContainerB responsive>
                  <thead style={{ display: 'none' }}>
                    <tr>
                      <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                        {intl.formatMessage({ id: 'DATE_TIME_LABEL' })}
                      </th>
                      <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                        {intl.formatMessage({ id: 'DEVICE_LABEL' })}
                      </th>
                      <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                        {intl.formatMessage({ id: 'AREA_LABEL' })}
                      </th>
                      <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                        {intl.formatMessage({ id: 'ALARM_HISTORY.TABLE.ALARM' })}
                      </th>
                      {/* <th style={{color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase'}}>
                        {intl.formatMessage({id: 'PERSON.PERSONS_TABLE_ACTIONS'})}
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>{intl.formatMessage({ id: 'NO.REGISTERS' })}</tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    {/* <tr></tr> */}
                  </tbody>
                </TableContainerB>
              </div>

              <div ref={LoadRef} style={{ height: '15vh' }}>
                <div className='loader-control'>
                  <Loading />
                </div>
              </div>
            </div>
          </div>
        </MonitoringContainer>
      </CardBody>
    </Card>
  );
}
