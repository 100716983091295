import React, { ReactNode } from 'react'

import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';

import LongMenu from 'src/_metronic/_partials/controls/MenuToolbarCard';

interface ContentPageProps {
  children: ReactNode,
  title: string
  addItemTitle: string
  linkPageCreate: string
  LongFilters: {
    pageSize: number,
    pageNumber: number,
    status: number,
    sortOrder: string,
    sortField: string,
    culture: string
  }
  logTotalItens: number,
  logUrl: string
  logNameFile: string
  logHeaders: string[]
  plusOptionsInToobar: boolean
  plusOptionsRender: ReactNode
  disabledOptionsHeader: boolean
  csvImport?: boolean

}
export function ContentPage({ csvImport = false, ...props }: ContentPageProps) {
  const {
    children,
    title,
    addItemTitle,
    linkPageCreate,
    LongFilters,
    logTotalItens,
    logUrl,
    logHeaders,
    logNameFile,
    plusOptionsInToobar,
    plusOptionsRender,
    disabledOptionsHeader
  } = props


  return (
    <Card fluidHeight={null} className={null}>
      <CardHeader
        icon={null}
        toolbar={null}
        className={null}
        labelRef={null}
        sticky={false}
        linkAddTitle={addItemTitle}
        linkAddTo={linkPageCreate}
        title={title}
      >


        {
          disabledOptionsHeader ? (<></>) : (
            <CardHeaderToolbar className={null}>

              {
                plusOptionsInToobar && (
                  plusOptionsRender
                )
              }

              {
                logUrl && (
                  <div className='d-flex mx-4'>
                    <LongMenu
                      filtros={LongFilters}
                      totalCount={logTotalItens}
                      linkApi={logUrl}
                      headers={logHeaders}
                      nomeArquivo={logNameFile}
                      csvImportDefault={csvImport}
                    />
                  </div>
                )
              }

            </CardHeaderToolbar>
          )
        }



      </CardHeader>

      <CardBody fit={null} fluid={null} className={null}>
        {children}
      </CardBody>
    </Card>
  )
}