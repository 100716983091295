import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';

import { ContentPage } from 'src/app/components/contentPage';

import { reasonBlockingService } from 'src/services/reasonBlocking.service';
import { execToast } from 'src/_metronic/_partials/controls/Toast';

import './create.style.sass'
import { useIntl } from 'react-intl';

import { AsyncPaginate } from 'react-select-async-paginate';
import { listBlockPeopleFunctions } from './functions';
import { I_COMPONENT_SELECT } from 'src/contracts/components';
import { DateTimeInput } from 'src/app/components/inputs/DateTimeInput';
import { listBlockPerson } from 'src/services/listBlockPerson.service';


interface IRENDERCOMPONENT {
  loadingMessage: string
  noOptionsMessage: string
  loadOptions: (args: I_COMPONENT_SELECT) => Promise<{
    options: any;
    hasMore: boolean;
    additional: {
      page: any;
    };
  }>
  update: (value: React.SetStateAction<I_COMPONENT_SELECT>) => void
  value: I_COMPONENT_SELECT
}
export function ListBlockCreate() {
  const intl = useIntl()
  const history = useHistory()
  const params = useParams()

  const [idUser, setIdUser] = useState<I_COMPONENT_SELECT>({
    label: '',
    value: 0
  })

  const [idReasonBlock, setIdReasonBlock] = useState<I_COMPONENT_SELECT>({
    label: '',
    value: 0
  })

  const [dateStart, setDateStart] = useState<Date | null>(null)
  const [dateEnd, setDateEnd] = useState<Date | null>(null)

  async function handleSave(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    try {

      if (!dateEnd || !dateStart || !idUser.value || !idReasonBlock.value) {
        throw new Error()
      }

      const payload = {
        "personId": idUser.value,
        "blockReasonId": idReasonBlock.value,
        "dtStart": listBlockPeopleFunctions.transformDateToDefaultApi(dateStart),
        "dtEnd": listBlockPeopleFunctions.transformDateToDefaultApi(dateEnd)
      }


      if ((params as any).id) {
        const id = (params as any).id
        await listBlockPerson.edit({
          ...payload,
          id
        })
        execToast('success', intl.formatMessage({ id: 'PAGE.LIST.REASONS.EDIT' }), 3000);
      } else {
        await listBlockPerson.save(payload)
        execToast('success', intl.formatMessage({ id: 'PAGE.LIST.REASONS.CREATE' }), 3000);
      }




      history.push('/blocked-people')
    } catch (e) {
      execToast('error', intl.formatMessage({ id: 'PAGE.LIST.REASONS.CREATE.ERROR' }), 3000);
    }
  }

  async function load(id: number) {
    const data = await listBlockPerson.getById(id)
    const valuesInMemory = data.data.data

    setIdUser({
      label: valuesInMemory.person.name,
      value: valuesInMemory.person.id
    })

    setIdReasonBlock({
      label: valuesInMemory.blockReasonDescription,
      value: valuesInMemory.blockReasonId
    })

    setDateStart(new Date(valuesInMemory.dtStart))
    setDateEnd(new Date(valuesInMemory.dtEnd))

  }
  function renderComponent({ loadOptions, loadingMessage, noOptionsMessage, update, value }: IRENDERCOMPONENT) {
    return (
      <AsyncPaginate
        loadingMessage={() => intl.formatMessage({ id: loadingMessage })}
        noOptionsMessage={() => intl.formatMessage({ id: noOptionsMessage })}
        debounceTimeout={500}
        styles={listBlockPeopleFunctions.selectStyle}
        closeMenuOnSelect
        placeholder={intl.formatMessage({ id: 'ACCESS_LOGS.PLACEHOLDERS.SELECT' })}
        loadOptions={loadOptions}
        loadOptionsOnMenuOpen
        onChange={(values: I_COMPONENT_SELECT) => {
          update(values);
        }}
        value={value}
      />
    )
  }

  useEffect(() => {
    if ((params as any).id) {
      load(+(params as any).id)
    }
  }, [])

  return (
    <ContentPage
      title={(params as any).id ? intl.formatMessage({ id: 'PAGE.LIST.BLOCKING.REASONS.EDIT' }) : intl.formatMessage({ id: 'PAGE.LIST.BLOCKING.REASONS.ADD' })}
      addItemTitle={intl.formatMessage({ id: 'PAGE.LIST.BLOCKING.REASONS.ADD' })}
      linkPageCreate=""
      LongFilters={{
        culture: '',
        pageNumber: 1,
        pageSize: 1000,
        sortField: '',
        sortOrder: 'description',
        status: 1
      }}
      logUrl='/reason-for-blocking/create'
      logTotalItens={0}
      logNameFile='Motivos bloqueios'
      logHeaders={[]}
      plusOptionsInToobar={false}
      plusOptionsRender={<></>}
      disabledOptionsHeader={true}
    >
      <form id="FormCreate" className='card-body' onSubmit={handleSave}>
        <div className='row'>
          <div className="col-lg-6 col-xl-6">
            <label htmlFor="">{intl.formatMessage({ id: "ACCESS_LOGS.INPUTS.NAME" })}</label>
            {
              renderComponent({
                loadingMessage: 'PERSON.PERSONS_TABLE_LOADING',
                loadOptions: listBlockPeopleFunctions.getPerson,
                noOptionsMessage: 'NOTHING_FOUND',
                update: (values) => setIdUser(values),
                value: idUser
              })
            }
          </div>

          <div className="col-lg-6 col-xl-6">
            <label htmlFor="">{intl.formatMessage({ id: "PAGE.BLOCKING.REASONS" })}</label>
            {
              renderComponent({
                loadingMessage: 'PERSON.PERSONS_TABLE_LOADING',
                loadOptions: listBlockPeopleFunctions.getReasonBlock,
                noOptionsMessage: 'NOTHING_FOUND',
                update: (values) => setIdReasonBlock(values),
                value: idReasonBlock
              })
            }
          </div>

        </div>

        <div className='row mt-5'>
          <div className="col-lg-6 col-xl-6">
            <DateTimeInput
              id="start"
              label={intl.formatMessage({ id: 'VISITOR.DATE.INITIAL' })}
              updateValue={(e) => {
                setDateStart(e)
              }}
              value={dateStart ? new Date(dateStart) : null}

            />
          </div>

          <div className="col-lg-6 col-xl-6">

            <DateTimeInput
              id="end"
              label={intl.formatMessage({ id: 'VISITOR.DATE.FINAL' })}
              updateValue={(e) => {
                setDateEnd(e)
              }}
              value={dateEnd ? new Date(dateEnd) : null}

            />
          </div>

        </div>

        <div className="col-lg-12 col-xl-12 mt-5 action">
          <div className='align-right'>
            <button
              type="button"
              onClick={() => {
                history.push('/blocked-people')
              }}
              className='btn btn-danger mr-5'>
              {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
            </button>
            <button
              type="submit"
              className='btn btn-primary btn-elevate'>
              {
                (params as any).id ? intl.formatMessage({ id: 'EDIT' }) : intl.formatMessage({ id: 'PERSON.NEWPERSON_SAVE_BUTTON' })
              }
            </button>
          </div>
        </div>
      </form>
    </ContentPage>
  )
} 