import Axios from 'axios'

class ConnectivityNotificationService {
  private url = `${process.env.REACT_APP_IDSECURE_API_URL}/connectivityNotification`

  create(payload: any) {
    return Axios.post(`${this.url}`, payload)
  }
  edit(payload: any) {
    return Axios.put(`${this.url}`, payload)
  }
  list(params: any) {
    return Axios.get(`${this.url}`, { params })
  }

  getById(id: number) {
    return Axios.get(`${this.url}/${id}`)
  }

  delete(id: number) {
    return Axios.delete(`${this.url}/${id}`)
  }

  activate(id: number) {
    return Axios.put(`${this.url}/${id}`)
  }
}

export const connectivityNotificationService = new ConnectivityNotificationService()
