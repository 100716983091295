import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';

import { ContentPage } from 'src/app/components/contentPage';

import { reasonBlockingService } from 'src/services/reasonBlocking.service';
import { execToast } from 'src/_metronic/_partials/controls/Toast';

import './create.style.sass'
import { useIntl } from 'react-intl';
export function ReasonBlockingCreate() {
  const intl = useIntl()
  const history = useHistory()
  const params = useParams()
  async function handleSave(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    try {
      const value = (e.target as any)['0'].value

      if (!value) return

      if ((params as any).id) {
        await reasonBlockingService.edit({
          description: value,
          id: (params as any).id,
          isEnabled: true
        })

        execToast('success', intl.formatMessage({ id: 'PAGE.BLOCKING.REASONS.EDIT' }), 3000);
      } else {
        await reasonBlockingService.save({
          description: value
        })

        execToast('success', intl.formatMessage({ id: 'PAGE.BLOCKING.REASONS.CREATE' }), 3000);
      }




      history.push('/reason-for-blocking')
    } catch (e) {
      execToast('error', intl.formatMessage({ id: 'PAGE.BLOCKING.REASONS.CREATE.ERROR' }), 3000);
    }
  }

  async function load(id: number) {
    const data = await reasonBlockingService.getById(id)

    const textInput: any = document.querySelector('[name="description"]')

    if (!textInput) return

    textInput.value = data.data.data.description
  }

  useEffect(() => {
    if ((params as any).id) {
      load(+(params as any).id)
    }
  }, [])

  return (
    <ContentPage
      title={(params as any).id ? intl.formatMessage({ id: 'PAGE.BLOCKING.REASONS.EDITP' }) : intl.formatMessage({ id: 'PAGE.BLOCKING.REASONS.ADD' })}
      addItemTitle={intl.formatMessage({ id: 'PAGE.BLOCKING.REASONS.ADD' })}
      linkPageCreate=""
      LongFilters={{
        culture: '',
        pageNumber: 1,
        pageSize: 1000,
        sortField: '',
        sortOrder: 'description',
        status: 1
      }}
      logUrl='/reason-for-blocking/create'
      logTotalItens={0}
      logNameFile='Motivos bloqueios'
      logHeaders={[]}
      plusOptionsInToobar={false}
      plusOptionsRender={<></>}
      disabledOptionsHeader={true}
    >
      <form id="FormCreate" className='card-body' onSubmit={handleSave}>
        <div className='row'>
          <div className="col-lg-12 col-xl-12">
            <label htmlFor="">{intl.formatMessage({ id: "SCHEDULE.SCHEDULE.DESCRIPTION" })}</label>
            <input type="text" className='form-control' name="description" />
          </div>

          <div className="col-lg-12 col-xl-12 mt-5 action">
            <div className='align-right'>
              <button
                type="button"
                className='btn btn-danger mr-5'
                onClick={() => {
                  history.goBack()
                }}
              >
                {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}</button>
              <button
                type="submit"
                className='btn btn-primary btn-elevate'>
                {
                  (params as any).id ? intl.formatMessage({ id: 'EDIT' }) : intl.formatMessage({ id: 'PERSON.NEWPERSON_SAVE_BUTTON' })
                }
              </button>
            </div>
          </div>
        </div>
      </form>
    </ContentPage>
  )
} 